<template>
  <div class="login-container">
    <div class="login-wrap">
      <div class="bg-container">
        <img class="bg-login" src="@/assets/images/login/bg1.png" alt />
      </div>
      <div class="login-form">
        <div class="login-title">智慧农业登录平台</div>
        <el-form :model="form" ref="form">
          <el-form-item label>
            <i class="el-icon-user"></i>
            <el-input
              v-model="username"
              placeholder="账号"
              @input="handleOnKeyup('username')"
            ></el-input>
          </el-form-item>
          <el-form-item label>
            <i class="el-icon-lock"></i>
            <el-input
              type="password"
              v-model="password"
              placeholder="密码"
              @keyup.enter.native="onSubmit"
              @input="handleOnKeyup('password')"
            ></el-input>
          </el-form-item>
          <el-button type="primary" @click="onSubmit">登录</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {},
      username: "",
      password: "",
      access_token: "",
    };
  },
  methods: {
    handleOnKeyup(type) {
      console.log(type);
      this[type] = this.handleKeyup(this[type]);
    },
    onSubmit() {
      if (!this.username) {
        this.$message({
          message: "请输入用户名",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      if (!this.password) {
        this.$message({
          message: "请输入用户密码",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      this.$api
        .getLogin({
          grant_type: "password",
          client_id: "client_2",
          client_secret: "123456",
          scope: "all",
          username: this.username, // user
          password: this.password, // 123456
        })
        .then((res) => {
          if (res.status == 200) {
            if (!res.userInfo.permissionList.length) {
              this.$message({
                message: "没有访问系统权限",
                type: "error",
                duration: 1500,
              });
              return;
            }
            this.access_token = res.token_type + " " + res.access_token;
            window.sessionStorage.setItem("token", this.access_token);
            window.sessionStorage.setItem(
              "userInfo",
              JSON.stringify(res.userInfo)
            );
            this.$api.token();
            this.$router.push({ path: "/home" });
            this.$message({
              message: "登录成功",
              type: "success",
              duration: 1500,
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* reset element-ui css */
/deep/.login-form {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      height: 47px;
      caret-color: #999;
    }
    input:-internal-autofill-selected {
      background-color: #fff !important;
    }
  }
  .el-form {
    width: 85%;
    margin: 0 auto;
    .el-form-item {
      font-size: 18px;
      .el-form-item__content {
        margin-left: 0 !important;
        border-bottom: solid 1px #ccc;
      }
    }
  }
  .el-button {
    background: #ff8033;
    border-color: #ff8033;
    color: #fff;
    width: 40%;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
  }
}
</style>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../assets/images/login/bg2.png") no-repeat center center;

  .login-wrap {
    display: flex;
    align-items: flex-start;
    width: 85%;
    height: 90%;
    background: url("../../assets/images/login/bg3.png") no-repeat center center;
    margin: 2% auto;
    overflow: hidden;
    .bg-container {
      height: 860px;
      padding: 10% 0 10% 10%;
      box-sizing: border-box;
      .bg-login {
        width: 70%;
      }
    }
    .login-form {
      width: 520px;
      padding: 160px 35px 0;
      text-align: center;
      .login-title {
        font-size: 26px;
        color: #eb6505;
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>
